@import url("https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.no-scroll-container {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.no-scroll-container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

button {
  cursor: pointer;
}

.comment-item:last-child{
  border: none;
}

/* material ui text field */
.css-u8wffo-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 1px solid #000 !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
